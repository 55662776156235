import { ParachainProps } from "./interfaces/Parachain";

const kusamaParachains: ParachainProps[] = [
  {
    wss: 'wss://kusama.api.onfinality.io/public-ws',
    paraId: 0,
    feedId: 0,
    chain: "kusama",
    chainName: "Kusama",
    web: "https://kusama.network/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://statemine.api.onfinality.io/public-ws',
    paraId: 1000,
    feedId: 1,
    chain: "statemine",
    chainName: "Statemine",
    web: "https://parachains.info/details/statemine",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://karura.api.onfinality.io/public-ws',
    paraId: 2000,
    feedId: 2,
    chain: "karura",
    chainName: "Karura",
    web: "https://acala.network/karura",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://bifrost-parachain.api.onfinality.io/public-ws',
    paraId: 2001,
    feedId: 3,
    chain: "bifrost",
    chainName: "Bifrost",
    web: "https://bifrost.finance/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://khala.api.onfinality.io/public-ws',
    paraId: 2004,
    feedId: 4,
    chain: "khala",
    chainName: "Khala",
    web: "https://phala.network/en/khala/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://shiden.api.onfinality.io/public-ws',
    paraId: 2007,
    feedId: 5,
    chain: "shiden",
    chainName: "Shiden",
    web: "https://phala.network/en/shiden/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://moonriver.api.onfinality.io/public-ws',
    paraId: 2023,
    feedId: 6,
    chain: "moonriver",
    chainName: "Moonriver",
    web: "https://moonbeam.foundation/moonriver-token/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://calamari.api.onfinality.io/public-ws',
    paraId: 2084,
    feedId: 7,
    chain: "calamari",
    chainName: "Calamari",
    web: "https://www.calamari.network/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://spiritnet.api.onfinality.io/public-ws',
    paraId: 2086,
    feedId: 8,
    chain: "kilt_spiritnet",
    chainName: "KILT Spiritnet",
    web: "https://www.kilt.io/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://basilisk.api.onfinality.io/public-ws',
    paraId: 2090,
    feedId: 9,
    chain: "basilisk",
    chainName: "Basilisk",
    web: "https://bsx.fi/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://altair.api.onfinality.io/public-ws',
    paraId: 2088,
    feedId: 10,
    chain: "altair",
    chainName: "Altair",
    web: "https://centrifuge.io/altair/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://parallel-heiko.api.onfinality.io/public-ws',
    paraId: 2085,
    chainName: "Parallel Heiko",
    chain: "parallel_heiko",
    feedId: 11,
    web: "https://parallel.fi/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://kintsugi.api.onfinality.io/public-ws',
    paraId: 2092,
    chainName: "Kintsugi BTC",
    chain: "kintsugi",
    feedId: 12,
    web: "https://kintsugi.interlay.io/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://pioneer-1-rpc.bit.country",
    paraId: 2096,
    chainName: "Bit.Country Pioneer",
    chain: "pionner",
    feedId: 13,
    web: "https://bit.country/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://node.genshiro.io",
    paraId: 2024,
    chainName: "Genshiro",
    chain: "genshiro",
    feedId: 14,
    web: "https://genshiro.equilibrium.io/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://us-ws-quartz.unique.network",
    paraId: 2095,
    chainName: "Quartz",
    chain: "quartz",
    feedId: 15,
    web: "https://unique.network/quartz/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://picasso-rpc.composable.finance",
    paraId: 2087,
    chainName: "Picasso",
    chain: "picasso",
    feedId: 16,
    web: "https://picasso.composable.finance/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://api.kusama.encointer.org",
    paraId: 1001,
    chainName: "Encointer",
    chain: "encointer",
    feedId: 17,
    web: "https://encointer.org/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://kusama.rpc.robonomics.network",
    paraId: 2048,
    chainName: "Robonomics",
    chain: "robonomics",
    feedId: 18,
    web: "https://robonomics.network/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://rpc-0.zeitgeist.pm",
    paraId: 2101,
    chainName: "Zeitgeist",
    chain: "zeitgeist",
    feedId: 19,
    web: "https://zeitgeist.pm",
    ecosystem: "kusama",
  },
  {
    wss: "wss://para.subsocial.network",
    paraId: 2100,
    chainName: "Subsocial",
    chain: "subsocial",
    feedId: 20,
    web: "https://subsocial.network/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://rpc.sakura.clover.finance",
    paraId: 2016,
    chainName: "Sakura",
    chain: "sakura",
    feedId: 21,
    web: "https://sakurafinance.io/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://rpc-shadow.crust.network/",
    paraId: 2012,
    chainName: "Crust Shadow",
    chain: "crust-shadow",
    feedId: 22,
    web: "https://crust.network/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://kusama.api.integritee.network/",
    paraId: 2015,
    chainName: "Integritee Network",
    chain: "integritee-network",
    feedId: 23,
    web: "https://integritee.network/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://crab-parachain-rpc.darwinia.network/",
    paraId: 2105,
    chainName: "Crab Network",
    chain: "crab-network",
    feedId: 24,
    web: "https://crab.network/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://rpc.litmus-parachain.litentry.io/",
    paraId: 2106,
    chainName: "Litmus",
    chain: "litmus",
    feedId: 25,
    web: "https://www.litentry.com/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://rpc.api.kico.dico.io/",
    paraId: 2107,
    chainName: "KICO",
    chain: "kico",
    feedId: 26,
    web: "https://dico.io/",
    ecosystem: "kusama",
  },
  {
    wss: 'wss://turing.api.onfinality.io/public-ws',
    paraId: 2114,
    chainName: "Turing Network",
    chain: "turing",
    feedId: 27,
    web: "https://oak.tech/turing/home/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://ws.parachain-collator-1.c1.sora2.soramitsu.co.jp",
    paraId: 2011,
    chainName: "Sora",
    chain: "sora",
    feedId: 28,
    web: "https://sora.org/",
    ecosystem: "kusama",
  },
  {
    wss: "wss://mangata-x.api.onfinality.io/public-ws",
    paraId: 21100,
    chainName: "Mangata",
    chain: "mangata",
    feedId: 44,
    web: "https://x.mangata.finance/",
    ecosystem: "kusama",
  },
];

const polkadotParachains: ParachainProps[] = [
  {
    wss: 'wss://polkadot.api.onfinality.io/public-ws',
    paraId: 2087,
    chainName: "Polkadot",
    chain: "polkadot",
    feedId: 29,
    web: "https://polkadot.network/",
    ecosystem: "polkadot",
  },

  {
    wss: "wss://statemint-rpc.polkadot.io",
    paraId: 1000,
    chainName: "Statemint",
    chain: "statemint",
    feedId: 30,
    web: "https://parachains.info/details/statemint",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://acala-rpc-0.aca-api.network",
    paraId: 2000,
    chainName: "Acala",
    chain: "acala",
    feedId: 31,
    web: "https://acala.network/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc.astar.network",
    paraId: 2006,
    chainName: "Astar",
    chain: "astar",
    feedId: 32,
    web: "https://astar.network/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc-para.clover.finance",
    paraId: 2002,
    chainName: "Clover Finance",
    chain: "clover",
    feedId: 33,
    web: "https://clover.finance/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://wss.api.moonbeam.network",
    paraId: 2004,
    chainName: "Moonbeam",
    chain: "moonbeam",
    feedId: 34,
    web: "https://moonbeam.network/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc.parallel.fi",
    paraId: 2012,
    chainName: "Parallel Finance",
    chain: "parallel_finance",
    feedId: 35,
    web: "https://parallel.fi/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc.composable.finance",
    paraId: 2019,
    chainName: "Composable",
    chain: "composable",
    feedId: 36,
    web: "https://www.composable.finance/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc.efinity.io",
    paraId: 2021,
    chainName: "Efinity",
    chain: "efinity",
    feedId: 37,
    web: "https://enjin.io/efinity-crowdloan",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://fullnode.parachain.centrifuge.io",
    paraId: 2031,
    chainName: "Centrifuge",
    chain: "centrifuge",
    feedId: 38,
    web: "https://centrifuge.io/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://api.interlay.io/parachain",
    paraId: 2032,
    chainName: "Interlay",
    chain: "interlay",
    feedId: 39,
    web: "https://interlay.io/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://rpc-01.hydradx.io",
    paraId: 2034,
    chainName: "HydraDX",
    chain: "hydradx",
    feedId: 40,
    web: "https://hydradx.io/",
    ecosystem: "polkadot",
  },
  {
    wss: 'wss://nodle-parachain.api.onfinality.io/public-ws',
    paraId: 2026,
    chainName: "Nodle",
    chain: "nodle",
    feedId: 41,
    web: "https://www.nodle.com/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://node.pol.equilibrium.io/",
    paraId: 2011,
    chainName: "Equilibrium",
    chain: "equilibrium",
    feedId: 42,
    web: "https://equilibrium.io/",
    ecosystem: "polkadot",
  },
  {
    wss: "wss://api.phala.network/ws",
    paraId: 2035,
    chainName: "Phala",
    chain: "phala",
    feedId: 43,
    web: "https://www.phala.network/",
    ecosystem: "polkadot",
  },
];

export const allChains = [...kusamaParachains, ...polkadotParachains];
